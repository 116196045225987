import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PolicyTopContainer = styled(Box)(
  ({ theme }) => `
  ${theme.breakpoints.down('lg')} {
    position: sticky;
    right: 0;
    width: 100vw;
  }
  z-index: 99;
`,
);

export const PolicyTopBox = styled(Box)(
  ({ theme }) => `
    .privacy-policy-link {
      display: inline-block;
      color: #00532A;
    }
    @media (max-width: 768px) {
      justify-content: space-between;
      .desc {
        font-size: 14px;
      }
    }
    @media (max-width: 320px) {
      padding: 12px 20px;
    }
    @media (max-width: 280px) {
      .desc {
        font-size: 12px;
      }
    }
    padding: 10px;
    font-size: 14px;
    background-color: #F5F5F5;
    border: 1px solid #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
`,
);

export const ClosePolicyTopButton = styled(Button)(
  ({ theme }) => `
  font-size: 14px;
  margin-left: 10px;
  height: 25px;
  padding: 0px;
`,
);
